<template>
    <div  class="communal course-statistics" ref="communal" >
        <!-- 顶部固定 -->
        <div class="top-fixed"  ref="topFixed">
          <div class="container statistics clearfix"  >
                <div>
                    <span class="title">测评日期</span>
                    <el-date-picker type="daterange" range-separator="-" start-placeholder="起" end-placeholder="终" v-model="search.date" value-format="yyyy-MM-dd" :editable="false" size="small" :picker-options="pickerOptions0"></el-date-picker>
                </div>
                <div>
                    <span class="title">测评结果</span>
                    <el-select filterable v-model="search.yinzi_title" placeholder="请选择" size="small" @change="yinzi_titleChange">
                        <el-option  v-for="item in testResultList" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                    <el-select filterable v-model="search.class_level" placeholder="请选择" size="small" :disabled="search.yinzi_title=='' || search.yinzi_title=='不限'">
                        <el-option  v-for="item in testLevelList" :key="item.level" :label="item.name" :value="item.level"></el-option>
                    </el-select>
                </div>    
                <div>
                    <span class="title">性别</span>
                    <el-select clearable v-model="search.sex" placeholder="请选择" size="small">
                        <el-option  v-for="item in sexList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </div>
                <div>
                    <span class="title">年级</span>
                    <el-select clearable v-model="search.grade" placeholder="请选择" size="small">
                        <el-option  v-for="item in gradeList" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </div>              
                <div v-for="(item,index) in selList" :key="index">
                    <span class="title">{{item.title}}</span>
                    <el-select v-model="item.value" clearable placeholder="请选择" size="small">
                        <el-option  v-for="(item,index) in item.option_list" :key="index" :label="item" :value="item"></el-option>
                    </el-select>
                </div>
                <div>
                   <el-button size="small" type="success" @click="btn('搜索')">搜索</el-button>
                   <el-button size="small" type="success" plain @click="btn('导出')">导出</el-button>
                </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="content"  >
            <div class="container" >
                  <div id="myChart" style="width:100%;height:400px"></div>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging"></div>
    </div>
</template>
<script>
import echarts from "echarts";
import { constants } from 'crypto';
import {GetOptionList,GradeList,ClassStatisticsList,ExportClassStatistics,publicUrl,YinziNameList,ScaleLevelList} from '../../api/api'
export default {
  data() {
    return {
      pickerOptions0: {
        disabledDate(time) {
         return time.getTime() > Date.now() - 8.64e6
       }
      },
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      search:{
          date:'',
          grade:'不限',
          sex:'0',
          yinzi_title:'不限',
          class_level:0
      },
      sexList:[{id:'0',name:'不限'},{id:'1',name:'男'},{id:'2',name:'女'}],
      selList:[],//下拉框列表
      gradeList:[],//年级
      testResultList:[],//评测结果列表
      testLevelList:[],//评测等级列表
    };
  },
  mounted() {
    var that=this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
       that.common.Count.pageResize(that);
    };
    that.common.communalApi.getTags(
        { url: GetOptionList, name: "selList", db: { } },
        this.callBack
    );
    that.common.communalApi.getTags(
        { url: YinziNameList, name: "testResultList", db: { } },
        this.callBack
    );
    that.common.communalApi.getTags(
        { url: ScaleLevelList, name: "testLevelList", db: { } },
        this.callBack
    );
  },
  created(){
    var date=new Date();
    var mouth=date.getMonth()+1
     if(mouth<10){
      mouth='0'+ mouth
    }
    var day=date.getDate()
     if(day<10){
      day='0'+ day
    }
    var time2=date.getUTCFullYear()+'-'+mouth+'-'+day
    var time1=date.getUTCFullYear()+'-'+mouth+'-01'
    this.search.date=[time1,time2]
   
    this.common.communalApi.getTags(
        { url: GradeList, name: "gradeList", db: { } },
        this.callBack
    );
  },
  methods: {
    callBack(db, ops) {
      this.$set(this, ops.name, db);
      if(ops.name=='selList'){
        this.getList()
      }
    },
    btn(name){
        this.getList(name)
    },
    getList(name){
        var from_date = "";
        var to_date = "";
        if (this.search.date != "" && this.search.date != null) {
            if (this.search.date.length>0){
                from_date = this.search.date[0];
                to_date = this.search.date[1];
            }
        }
        var othertext=''
        var db={
            from_date:from_date,
            to_date:to_date,
            sex:this.search.sex,
            grade:this.search.grade,
            yinzi_title :this.search.yinzi_title,  
            class_level  :this.search.class_level 
        }
        if(this.selList.length>0){
            var arr=[]
            for (let [key, value] of this.selList.entries()) {
              var json={
                  option_id:value.option_id,
                  value:value.value
              }
              arr.push(json)
            }
            db.othertext=JSON.stringify(arr)
        }else{
            db.othertext=''
        }
        if(name!='导出'){
            this.$axios.post(ClassStatisticsList, db).then(
                res => {
                    if (res.data.code == 1) {
                    this.drawLine(res.data.data);
                    }else{
                    
                    } 
                },
                err => {}
            );
        }else{
            window.location.href = publicUrl+'/adminapi/Classscaler/ExportClassStatistics?from_date=' + from_date + '&to_date=' + to_date + '&sex=' + this.search.sex + '&grade=' + this.search.grade+ '&othertext=' + db.othertext+ '&yinzi_title=' + db.yinzi_title+ '&class_level=' + db.class_level
        }
       
    },
    drawLine(data) {
        let myChart = echarts.init(document.getElementById("myChart"));
        myChart.setOption(data,true);
     },
     yinzi_titleChange(){
         if(this.search.yinzi_title=='不限'){
            this.search.class_level=0
         }
     },
  }
};
</script>

<style lang="scss">
.course-statistics{
    .statistics{
      padding: 20px 20px 10px !important;
      .el-input__inner {
            width: 150px;
        }
        .el-range-editor--small.el-input__inner {
            width: 220px !important;
        }
        .title {
            padding-right: 10px;
            color: rgba(80, 80, 80, 1);
            font-size: 14px
        }
    }
    .statistics>div {
            float: left;
            padding-bottom: 10px;
        }
    .statistics>div:not(:last-child) {
        padding-right: 40px;
    }
}

</style>
